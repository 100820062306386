@import ../../../styles/helpers

.section
    overflow: hidden

.head
    margin-bottom: 64px
    text-align: center
    +m
        margin-bottom: 32px

.wrapper
    margin: 0 -16px

.item
    display: flex !important
    align-items: center

.preview
    flex-shrink: 0
    width: 160px
    +d
        width: 112px
    img
        width: 100%

.details
    flex-grow: 1
    padding: 0 24px 0 16px
    +x
        padding-right: 0
    +d
        padding: 0 0 0 16px

.subtitle
    margin-bottom: 8px
    +body-bold-2