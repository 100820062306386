@import ../../../../styles/helpers

.gallery
    +m
        margin: 0 -24px 0 -16px

.list
    display: grid

.stays
    margin-right: 8px
    grid-gap: 8px
    grid-template-columns: repeat(4,1fr)
    grid-template-rows: repeat(3,1fr)
    +m
        grid-template-columns: repeat(3,1fr)
        grid-template-rows: repeat(4,1fr)
    .preview
        &:first-child
            grid-column: 1 / 4
            grid-row: 1 / 4
            +m
                grid-column: 1 / 4
                grid-row: 1 / 4
        &:nth-child(2)
            grid-column: 4 / 5
            grid-row: 1 / 2
            +m
                grid-column: 1 / 2
                grid-row: 4 / 5
        &:nth-child(3)
            grid-column: 4 / 5
            grid-row: 2 / 3
            +m
                grid-column: 2 / 3
                grid-row: 4 / 5
        &:nth-child(4)
            grid-column: 4 / 5
            grid-row: 3 / 4
            +m
                grid-column: 3 / 4
                grid-row: 4 / 5

.cars
    margin-right: 8px
    grid-gap: 8px
    grid-template-columns: repeat(3,1fr)
    grid-template-rows: repeat(3,1fr)
    +m
        grid-template-columns: repeat(2,1fr)
        grid-template-rows: repeat(3,1fr)
    .preview
        &:first-child
            grid-column: 1 / 3
            grid-row: 1 / 3
            +m
                grid-column: 1 / 3
                grid-row: 1 / 2
        &:nth-child(2)
            grid-column: 3 / 4
            grid-row: 1 / 3
            +m
                grid-column: 1 / 2
                grid-row: 2 / 3
        &:nth-child(3)
            grid-column: 1 / 2
            grid-row: 3 / 4
            +m
                grid-column: 2 / 3
                grid-row: 2 / 3
        &:nth-child(4)
            grid-column: 2 / 3
            grid-row: 3 / 4
            +m
                grid-column: 1 / 2
                grid-row: 3 / 4
        &:nth-child(5)
            grid-column: 3 / 4
            grid-row: 3 / 4
            +m
                grid-column: 2 / 3
                grid-row: 3 / 4

.tour
    margin-right: 8px
    grid-gap: 8px
    grid-template-columns: repeat(4,1fr)
    grid-template-rows: repeat(2,1fr)
    +m
        grid-template-columns: repeat(2,1fr)
        grid-template-rows: repeat(4,1fr)
    .preview
        &:first-child
            grid-column: 1 / 3
            grid-row: 1 / 3
            +m
                grid-column: 1 / 3
                grid-row: 1 / 3
        &:nth-child(2)
            grid-column: 3 / 4
            grid-row: 1 / 2
            +m
                grid-column: 1 / 2
                grid-row: 3 / 4
        &:nth-child(3)
            grid-column: 4 / 5
            grid-row: 1 / 2
            +m
                grid-column: 2 / 3
                grid-row: 3 / 4
        &:nth-child(4)
            grid-column: 3 / 4
            grid-row: 2 / 3
            +m
                grid-column: 1 / 2
                grid-row: 4 / 5
        &:nth-child(5)
            grid-column: 4 / 5
            grid-row: 2 / 3
            +m
                grid-column: 2 / 3
                grid-row: 4 / 5
    
.preview
    position: relative
    cursor: pointer
    img
        width: 100%
        height: 100%
        border-radius: 16px
        object-fit: cover
    &:after
        content: ""
        position: absolute
        top: 50%
        left: 50%
        z-index: 2
        width: 48px
        height: 48px
        transform: translate(-50%,-50%)
        border-radius: 50%
        box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1)
        background: $n8 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cpath fill='%23777e91' fill-rule='evenodd' d='M5.666.334C8.612.334 11 2.722 11 5.667a5.31 5.31 0 0 1-1.12 3.27l3.592 3.592c.26.26.26.682 0 .943s-.682.26-.943 0L8.937 9.881a5.31 5.31 0 0 1-3.27 1.12c-2.946 0-5.333-2.388-5.333-5.333S2.721.334 5.666.334zm0 1.333a4 4 0 0 0 0 8 4 4 0 0 0 0-8zm0 1.333c.368 0 .667.298.667.667h0v1.333h1.333c.368 0 .667.298.667.667s-.298.667-.667.667h0-1.333v1.333c0 .368-.298.667-.667.667S5 8.035 5 7.667h0V6.334H3.667c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h0H5V3.667c0-.368.298-.667.667-.667z'/%3E%3C/svg%3E") no-repeat 50% 50% / 14px auto
        opacity: 0
        transition: opacity .2s
        pointer-events: none
    &:hover
        &:after
            opacity: 1
    .button
        position: absolute
        left: 24px
        bottom: 24px
        +m
            left: 16px
            bottom: 16px

.view
    width: 100%
    height: 100%