@import ../../styles/helpers

.modal
  display: flex
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  padding: 48px
  background: rgba($n1,.3)
  opacity: 0
  animation: showModal .4s forwards
  overflow: auto
  z-index: 999
  +m
    padding: 32px 16px
  +dark
    background: rgba($n2,.8)

@keyframes showModal
  0%
    opacity: 0
  100%
    opacity: 1

.outer
  position: relative
  width: 100%
  max-width: 544px
  margin: auto
  background: $n8
  border-radius: 16px
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08)
  z-index: 2
  +dark
    background: $n1
  & > div
    padding: 32px 24px
    +m
      padding: 32px 24px

.close
    position: absolute
    top: 20px
    right: 24px
    width: 40px
    height: 40px
    background: $n8
    border: 2px solid $n6
    border-radius: 50%
    font-size: 0
    +t
        display: inline-block
    svg
        fill: $n2
        transition: transform .2s
    +dark
        border-color: $n3
        background: $n1
        svg
            fill: $n8
    &:hover
      svg
        transform: rotate(90deg)


