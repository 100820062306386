@import ../../../../styles/helpers

.item
    padding: 64px 32px
    border-radius: 20px
    background: $n7
    +m
        padding: 48px 24px
    +dark
        background: $n2

.icon
    display: flex
    align-items: center
    height: 48px
    margin-bottom: 32px
    img
        max-height: 100%

.title
    margin-bottom: 16px
    +body-bold-2

.content
    color: $n4
