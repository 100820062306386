@import ../../../styles/helpers

.inner
    position: relative

.head
    margin-bottom: 80px
    padding-right: 120px
    +d
        margin-bottom: 64px
    +m
        margin-bottom: 40px
        padding: 0

.headSmall
    margin-bottom: 48px
    +m
        margin-bottom: 40px

.info
    margin-top: 12px

.wrapper
    margin: 0 -16px
    +m
        margin: 0 -8px