@import ../../../../styles/helpers

.item
    align-items: center

.preview
    width: 520px
    +t
        width: 470px
    +m
        width: 370px
    img
        width: 100%
        height: auto
        object-fit: contain
