[class^="category"]
  display: inline-block
  padding: 11px 8px 9px
  background: $n8
  border-radius: 4px
  +hairline-2
  color: $n3
    
.category-blue
  background: $p1
  color: $n8

.category-green
  background: green
  color: $n8

.category-orange
  background: orange
  color: $n8