@import ../../../../styles/helpers

.item
    display: flex
    flex-direction: column
    align-items: center
    overflow: hidden
    border-radius: 16px
    padding: 8px 8px 24px
    background: $n8
    text-align: center
    color: $n2
    transition: box-shadow .2s
    &:hover
        box-shadow: inset 0 0 0 1px $n5
    +dark
        background: $n2
        color: $n8
        &:hover
            box-shadow: inset 0 0 0 1px $n4

.icon
    position: relative
    overflow: hidden
    width: 80px
    height: 80px
    display: flex
    justify-content: center
    align-items: center
    width: 38px
    height: 38px
    border-radius: 38px
    margin: 0 auto 16px
    margin-top: 16px
    background: $p4
    svg
        fill: $n8

.title
    margin-bottom: 4px
    +body-bold-2

.subtitle
    +caption-2
    color: $n4


.ModalLine
    display: flex
    justify-content: space-between
    align-items: flex-start
    margin-bottom: 15px

.ModalTitle
    +body-bold-2

.body
    flex-grow: 1
    padding: 0px 22px

.details
    display: block
    flex-wrap: wrap
    margin: -8px 0 0px -12px

.detail
    display: flex
    margin: 8px 0 0 12px
    +caption-2
    color: $n4
    svg
        flex-shrink: 0
        margin: 1px 6px 0 0
        fill: $n4
