@import ../../styles/helpers

.label
    margin-bottom: 12px
    +hairline-2
    color: $n5

.wrap
    font-size: 0

.textarea
    width: 100%
    height: 140px
    padding: 0px 14px
    border-radius: 12px
    resize: none
    box-shadow: inset 0 0 0 2px $n6
    border: 2px solid $n6
    background: $n8
    +poppins
    +caption-bold-1
    font-size: 14px
    font-weight: 500
    line-height: 48px
    transition: box-shadow .2s
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    +dark
        border-color: $n3
        color: $n8
        box-shadow: inset 0 0 0 2px $n3
        background: none
    +placeholder
        color: $n4
    &:focus
        border-color: $n4
    &:hover
        box-shadow: inset 0 0 0 2px $n4
