@import ../../styles/helpers

.grid
    display: flex
    margin-left: -32px

.column
    padding-left: 32px

.preview
    margin-top: 32px
    +m
        margin-top: 16px
    img
        width: 100%
        border-radius: 16px

.foot
    margin-top: 32px
    text-align: center

.head
    margin-bottom: 34px
    margin-top: 34px
    text-align: center
    +m
        margin-bottom: 48px
