@import ../../../styles/helpers

.container
    padding: 20px
    display: flex
    justify-content: center
    align-items: center

.col
    max-width: 500px
    margin: 0 auto
    text-align: left


.headText
    color: $n2
    background-color: transparent
    padding: 5px 15px
    border: 2px solid $n2
    border-radius: 12px
    display: inline-block
    margin-bottom: 50px
    font-size: 14px
    font-weight: 400
    +dark
        color: $n8
        border-color: $n8



.modalTitle
    color: $n2
    font-size: 36px
    margin-bottom: 30px
    +dark
        color: $n8



.info
    color: $n2
    margin-bottom: 30px
    max-width: 260px
    font-size: 16px
    font-weight: 500
    +dark
        color: $n8

