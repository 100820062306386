@import ../../../../styles/helpers

.success
    padding-top: 40px

.title
    margin-bottom: 32px
    text-align: center

.info
    max-width: 70%
    margin: 0 auto 32px
    text-align: center
    +body-bold-2
    span
        color: $p4

.list
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: center
    padding: 24px
    border-radius: 12px
    border: 1px solid $n6
    +dark
        border-color: $n3

.item
    display: flex
    justify-content: space-between
    width: 100%
    align-items: center

.category
    color: $n4

.content
    font-weight: 500
    color: $n2
    +dark
        color: $n8

.btns
    display: flex
    margin: 32px -8px 0

.button
    flex: 0 0 calc(50% - 16px)
    width: calc(50% - 16px)
    margin: 0 8px

