@import "../../../../styles/helpers"

.card
    width: 100%
    padding: 50px 32px
    border-radius: 20px
    background: $n8
    text-align: center
    transition: all .2s
    &:hover
        box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1)
    +dark
        background: $n2
        box-shadow: inset 0 0 0 2px $n2
        &:hover
            background: transparent

.preview
    display: flex
    justify-content: center
    align-items: center
    height: 160px
    margin: 0 auto 32px
    img
        max-width: 100%
        max-height: 100%

.rightCol
    margin-left: 10px

.content
    margin-bottom: 16px
    color: $n4

.list
    display: flex
    flex-direction: column
    align-items: flex-start
    text-align: left

.currency
    display: flex
    align-items: center
    justify-content: center

.pricingItem
    display: flex
    align-items: center

.optionText
    flex: 1
    display: flex
    flex-wrap: wrap
    display: flex

.option
    display: flex
    flex-direction: row
    margin-top: 24px
    color: $n4
    svg
        margin-right: 16px
        fill: $n4
        