@import ../../../styles/helpers

.head
    margin-bottom: 64px
    text-align: center
    +d
        margin-bottom: 48px

.title
    margin-bottom: 12px

.wrapper
    margin: 0 -16px
    +m
        margin: 0 -8px