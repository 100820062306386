.container
    width: 100%
    max-width: 1280px
    margin: 0 auto
    padding: 0 80px
    +t
        padding: 0 40px
    +m
        padding: 0 32px

.containerCS
    width: 100%
    max-width: 1280px
    margin: 0 auto
    padding: 20px 0
    +t
        padding: 20px 40px
    +m
        padding: 20px 32px