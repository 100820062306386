@import ../../../styles/helpers

.section
    position: relative
    padding: 308px 0 376px
    overflow: hidden
    +d
        padding: 252px 0
    +m
        padding: 200px 0

.container
    max-width: 746px

.title
    margin-bottom: 16px
    text-align: center

.info
    max-width: 482px
    margin: 0 auto 32px
    text-align: center
    +body-2
    color: $n4 

.form
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12)
    +t
        max-width: 480px
        margin: 0 auto

.preview
    position: absolute
    z-index: -1
    &:first-child
        top: 28px
        left: calc(50% - 790px)
        +m
            top: -50px
            left: calc(50% - 240px)
            img
                width: 300px
    &:nth-child(2)
        top: 45px
        right: calc(50% - 640px)
        +d
            top: 0
        +m
            top: 45px
            right: calc(50% - 310px)
            img
                width: 300px
    &:nth-child(3)
        right: calc(50% - 740px)
        bottom: 100px
    &:nth-child(4)
        left: calc(50% - 360px)
        bottom: 80px
        +d
            bottom: 10px
        +m
            left: calc(50% - 200px)
            img
                width: 300px
