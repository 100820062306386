@import ../../../styles/helpers

.main
    padding-top: 80px
    +d
        margin-bottom: 80px
    +m
        margin-bottom: 32px
        padding-top: 40px

.head
    max-width: 730px
    margin: 0 auto 80px
    text-align: center
    +m
        margin-bottom: 32px

.title
    margin-bottom: 24px

.breadcrumbs
    justify-content: center

.subtitle
    color: $n4

.info
    +caption-2
    font-weight: 500
    color: $n4

.topBar
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 20px