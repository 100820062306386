@import ../../../../styles/helpers

.icon
    position: relative
    overflow: hidden
    width: 80px
    height: 80px
    justify-content: center
    align-items: center
    width: 24px
    height: 24px
    border-radius: 24px
    margin: 0 auto 16px
    margin-top: 16px
    background: $p4
    svg
        fill: $n8

.head
    margin-bottom: 64px
    text-align: center
    +m
        margin-bottom: 48px

.wrapper
    margin: 0 -16px
    +m
        margin: 0 -4px


.btns
    margin-top: 64px
    text-align: center
    +t
        margin-top: 40px

.button
    .loader
        margin: 0 16px 0 5px
        transform: scale(.8)

.ModalLine
    display: flex
    justify-content: space-between
    align-items: flex-start
    margin-bottom: 15px

.ModalTitle
    +body-bold-2

.body
    flex-grow: 1
    padding: 0px 22px



.section
    overflow: hidden

.head
    margin-bottom: 64px
    text-align: center
    +m
        margin-bottom: 32px

.wrapper
    margin: 0 -16px

.item
    display: flex !important
    align-items: center

.preview
    flex-shrink: 0
    width: 160px
    +d
        width: 112px
    img
        width: 100%

.details
    flex-grow: 1
    padding: 0 24px 0 16px
    +x
        padding-right: 0
    +d
        padding: 0 0 0 16px

.descriptions
    flex-grow: 1
    padding: 0
    +x
        padding-right: 0
    +d
        padding: 0

.subtitle
    margin-bottom: 8px
    +body-bold-2