@import ../../../styles/helpers

.container
    display: flex
    align-items: center
    +m
        display: block

.wrap
    max-width: 352px
    margin-right: auto
    +m
        max-width: 100%
        margin: 0 0 40px

.title
    margin-bottom: 16px

.info
    margin-bottom: 40px
    +body-2
    color: $n4

.list
    margin-bottom: 40px

.item
    display: flex
    &:not(:last-child)
        margin-bottom: 16px
    
.number
    flex-shrink: 0
    width: 44px
    margin-right: 16px
    border-radius: 12px
    text-align: center
    line-height: 24px
    font-weight: 500
    color: $n8

.text
    +body-bold-2

.gallery
    position: relative
    width: 544px
    +d
        width: 450px
        margin-right: -50px
    +t
        width: 400px
        margin-right: -150px
    +m
        width: 100%
        margin: 0

.preview
    &:nth-child(2)
        top: 25%
        left: -17.5%
        width: 53%
    &:nth-child(3)
        right: 17%
        bottom: 21.5%
        width: 47%
    &:nth-child(4)
        top: 12%
        right: 19.5%
        width: 14.7%
    &:not(:first-child)
        position: absolute
        z-index: 2
        filter: drop-shadow(0px 20px 32px rgba(15, 15, 15, 0.1))
    img
        width: 100%