@import ../../../styles/helpers

.section
    overflow: hidden

.head
    margin-bottom: 64px
    text-align: center
    +m
        margin-bottom: 32px

.wrapper
    margin: 0

.item
    display: flex !important
    align-items: center

.details
    flex-grow: 1
    padding: 0 24px 0 16px
    +x
        padding-right: 0
    +d
        padding: 0 0 0 16px

.subtitle
    margin-bottom: 8px
    +body-bold-2