@import ../../../../styles/helpers

.icon
    position: relative
    overflow: hidden
    width: 80px
    height: 80px
    justify-content: center
    align-items: center
    width: 24px
    height: 24px
    border-radius: 24px
    margin: 0 auto 16px
    margin-top: 16px
    background: $p4
    svg
        fill: $n8

.head
    margin-bottom: 64px
    text-align: center
    +m
        margin-bottom: 48px

.wrapper
    margin: 0 -16px
    +m
        margin: 0 -4px

.item
    text-align: center
    color: $n2
    transition: color .2s
    &:hover
        color: $p1
        .preview
            img
                transform: scale(1.05)
    +dark
        color: $n8
        &:hover
            color: $p1

.preview
    margin-bottom: 24px
    overflow: hidden
    border-radius: 16px
    img
        width: 100%
        transition: transform 1s
    
.subtitle
    margin-bottom: 8px
    +body-bold-2

.text
    color: $n4

.btns
    margin-top: 64px
    text-align: center
    +t
        margin-top: 40px

.button
    .loader
        margin: 0 16px 0 5px
        transform: scale(.8)

.ModalLine
    display: flex
    justify-content: space-between
    align-items: flex-start
    margin-bottom: 15px

.ModalTitle
    +body-bold-2

.body
    flex-grow: 1
    padding: 0px 22px

.details
    display: block
    flex-wrap: wrap
    margin: -8px 0 0px -12px

.detail
    display: flex
    margin: 8px 0 0 12px
    +caption-2
    color: $n4
    svg
        flex-shrink: 0
        margin: 1px 6px 0 0
        fill: $n4
