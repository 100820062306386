@import ../../../styles/helpers

.head
    margin-bottom: 64px
    text-align: center
    +m
        margin-bottom: 32px

.body
    position: relative
    max-width: 1024px
    margin: 0 auto

.line
    position: absolute
    top: 66px
    left: 50%
    transform: translateX(-50%)
    +t
        top: 24px
    +m
        display: none
    +dark
        path
            stroke: $n3 !important

.list
    position: relative
    z-index: 3
    display: flex
    +m
        display: block
        max-width: 256px
        margin: 0 auto

.item
    flex: 0 0 256px
    text-align: center
    +t
        flex: 0 0 200px
    &:not(:last-child)
        margin-right: auto
        +m
            margin: 0 0 64px

.preview
    img
        width: 100%
    
.subtitle
    margin-bottom: 8px
    +body-bold-1

.text
    color: $n4