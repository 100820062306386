@import ../../styles/helpers

.title
    margin-bottom: 10px
    +body-bold-1

.subtitle
    margin-right: 4px
    transition: color .2s

.description
    font-weight: 400
    color: $n4
    margin-bottom: 10px