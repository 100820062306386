@import ../../styles/helpers

.title
    margin-bottom: 32px
    padding-right: 56px
    +poppins
    font-size: 24px
    line-height: 32px
    font-weight: 600
    svg
        position: relative
        top: -2px
        margin-right: 16px
        +m
            top: 0
        +dark
            fill: $n8

.field,
.wrap,
.box,
.sign
    &:not(:last-child)
        margin-bottom: 32px

.wrap
    display: flex
    padding: 20px 24px
    border-radius: 4px
    background: $n7
    +dark
        background: $n3

.category
    margin-right: auto
    font-weight: 500
    color: $n3
    +dark
        color: $n6

.details
    text-align: right

.currency
    +body-bold-2

.price
    color: $n4

.sign
    display: flex
    justify-content: center
    align-items: center
    width: 32px
    height: 32px
    border-radius: 50%
    background: $n6
    svg
        fill: $n3
    +dark
        background: $n3
        svg
            fill: $n6

.box
    position: relative
    .button
        position: absolute
        top: 36px
        right: 16px
        height: 24px
        padding: 0 16px
        border-radius: 12px

.transfer > .button
    width: 100%


.inner
    +t
        position: relative
        max-width: 455px
        margin: auto
        padding: 24px
        border-radius: 24px
        background: $n8
    +m
        padding: 24px 16px
    +dark
        background: $n1

.close
    display: none
    position: absolute
    top: 20px
    right: 24px
    width: 40px
    height: 40px
    background: $n8
    border: 2px solid $n6
    border-radius: 50%
    font-size: 0
    +t
        display: inline-block
    svg
        fill: $n2
        transition: transform .2s
    +dark
        border-color: $n3
        background: $n1
        svg
            fill: $n8

.card
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08)