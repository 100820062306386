@import ../../../styles/helpers

.section
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 100vh
  color: $n8
  text-align: center
  background: linear-gradient(to bottom, $n8 0%, #ECE9E6 50%, transparent 100%)
  overflow: hidden
  z-index: 1
  +dark
    color: $n1
    background: linear-gradient(to bottom, #333 0%, #666 50%, transparent 100%)
    .background
      &:after
        background: linear-gradient(to top, $n1, transparent)

.background
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  z-index: -1
  &:after
    content: ""
    position: absolute
    bottom: 0
    left: 0
    right: 0
    height: 50vh
    background: linear-gradient(to top, $n8, transparent)
    z-index: 2
  +dark
    &:after
      background: linear-gradient(to top, $n1, transparent)

.blob
  position: absolute
  border-radius: 50%
  filter: blur(80px)
  mix-blend-mode: screen
  animation: moveBlob 20s infinite alternate ease-in-out
  opacity: 0.75
  &:nth-child(1)
    background: linear-gradient(132deg, #7BDCB5, #8ED1FC)
    width: 80vw
    height: 80vw
    animation-duration: 60s
    top: 10vh
    left: 10vw
    +dark
      background: linear-gradient(132deg, #3a7d5d, #3a7d5d)
  &:nth-child(2)
    background: linear-gradient(132deg, #E0EFCF, #FF9CEE)
    width: 90vw
    height: 90vw
    animation-duration: 80s
    bottom: 10vh
    right: 10vw
    +dark
      background: linear-gradient(132deg, #6e8f6e, #6e8f6e)
  &:nth-child(3)
    background: linear-gradient(132deg, #FDEB71, #F8D800)
    width: 70vw
    height: 70vw
    animation-duration: 100s
    bottom: 30vh
    left: 50%
    transform: translateX(-50%) translateY(0)
    +dark
      background: linear-gradient(132deg, #8f8f6e, #8f8f6e)

@keyframes moveBlob
  0%
    transform: translateY(0) translateX(0) scale(1)
  25%
    transform: translateY(50vh) translateX(50vw) scale(1.2)
  50%
    transform: translateY(0vh) translateX(-50vw) scale(1.5)
  75%
    transform: translateY(-50vh) translateX(-50vw) scale(0.8)
  100%
    transform: translateY(0) translateX(0) scale(1)

.container
  position: relative
  max-width: 900px
  z-index: 2

.title
  margin-bottom: 16px
  color: #000
  +dark
    color: $n8
  +m
    font-size: 40px

.info
  max-width: 482px
  margin: 0 auto 32px
  @include body-2
  color: $n4

.wrap
    margin-right: auto
    +m
        position: absolute
        top: 0
        left: 0
        z-index: 10
        display: flex
        flex-direction: column
        width: 100%
        height: 100vh
        padding: 100px 0 32px
        background: $n8
        visibility: hidden
        opacity: 0
        transition: all .2s
        +dark
            background: $n1
        &.visible
            visibility: visible
            opacity: 1
    & > .button
        display: none
        +m
            display: flex
            margin: auto 24px 0
    .btns
        display: none
        +m
            display: flex

.control
    display: flex
    align-items: center
    justify-content: center
    .button
        display: none
        +m
            display: flex

.animatedText
  display: inline-block
  width: 100px
  vertical-align: bottom
  text-align: center
  transition: opacity 1s ease-in-out

.animatedText.animate
  animation: fadeInOut 5s infinite

.hidden
  opacity: 0

@keyframes fadeInOut
  0%, 25%, 75%, 100%
    opacity: 0 
  50% 
    opacity: 1 

